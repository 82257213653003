<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.tabs-solid
  .nav-tabs
    .nav-item
      a
        border-radius: 2px !important
      .nav-link
        border-radius: 0px !important
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-top(style='min-height: 300px')
    .col-9
      .mb-4
      h3.title.py-3 카카오 알림톡 발신자 설정
      //- p 문의 바랍니다.

      b-tabs.tabs-solid.shadow-sm.border.rounded.bg-white(card v-model='tab_index')
        b-tab(title="채널 프로필 관리")
          table.w-100
            tbody
              tr.border-bottom(v-for='profile in $store.state.kakao_config.profiles')
                td
                  strong.mr-2 {{profile.channel_id}}
                  //- pre(style='font-size:11px') {{profile}}
                td.w-50
                  button.btn.btn-default.btn-sm.p-1(type='button' @click='delete_profile(profile)') 삭제
                  button.btn.btn-default.btn-sm.p-1(type='button' @click='refresh_channel_status(profile)') 새로고침
                  button.btn.btn-default.btn-sm.p-1(type='button' @click='test_profile(profile)') 테스트 발송
                  button.btn.btn-default.btn-sm.p-1(type='button' @click='view_template(profile)') 템플릿 조회
        b-tab(title="템플릿 관리")
          .form-group
            label 프로필 선택
            select.form-control(v-model='current_template_profile')
              option(v-for='profile in $store.state.kakao_config.profiles' :value='profile.id') {{profile.channel_id}}
          div(v-for='profile in $store.state.kakao_config.profiles' v-if='profile.id == current_template_profile')
            table.w-100
              tbody
                tr.border-bottom(v-for='template in profile.templates')
                  td(style='width: 100px')
                    span(v-if='template.status == "S"') 중단
                    span(v-else-if='template.status == "A"') 정상
                    span(v-else-if='template.status == "R"') 대기(발송전)
                    span(v-else) 미승인
                    small(v-show='template.review_requested_at')
                      br
                      | 검수대기중 ({{template.review_requested_at | datetime}})
                    br
                    small(v-if='template.review_status == "REG"') 등록됨 (심사대기)
                    small(v-else-if='template.review_status == "REQ"') 심사요청됨 (심사중)
                    small(v-else-if='template.review_status == "APR"') 승인 (심사완료)
                    small(v-else-if='template.review_status == "REJ"') 반려 (심사완료)
                  td
                    strong.mr-2 {{template.name}} ({{template.code}})
                  td.w-50
                    button.btn.btn-default.btn-sm.p-1(type='button' @click='request_template_review(template)') 검수요청
                    button.btn.btn-default.btn-sm.p-1(type='button' @click='refresh_template(template)') 새로고침
                    button.btn.btn-default.btn-sm.p-1(type='button' @click='delete_template(template)') 삭제
                    button.btn.btn-default.btn-sm.p-1(type='button' @click='edit_template(template)') 수정
                    button.btn.btn-default.btn-sm.p-1(type='button' @click='test_template(template)' :disabled='template.status != "R" && template.status != "A" ') 테스트 발송
                    .border(v-show='template.json.comments')
                      table.table.table-sm
                        thead
                          tr.bg-light
                            th 검수상태
                            th 검수의견
                            th 검수자
                            th 일시
                            th 첨부자료
                        tbody
                          tr(v-for='c in template.json.comments')
                            td {{c.status}}
                            td {{c.content}}
                            td {{c.userName}}
                            td {{c.created_at}}
                            td {{c.attachment}}

            .p-4(v-if='current_template_edit && current_template_edit.id')
              h5 템플릿 수정
              .form-group
                form(@submit.prevent='submit_template_edit')
                  .form-group
                    label 템플릿 코드
                    input.form-control(v-model='current_template_edit.json.templateCode' )
                  .form-group
                    label 템플릿 이름
                    input.form-control(v-model='current_template_edit.json.templateName')
                  .form-group
                    label 메세지 유형
                    select.form-control(v-model='current_template_edit.json.templateMessageType')
                      option(value='BA') 기본형
                      //- option(value='EX') 부가정보형
                      //- option(value='EX') 광고추가형
                      //- option(value='MI') 복합
                  .form-group
                    label 템플릿 강조 유형
                    select.form-control(v-model='current_template_edit.json.templateEmphasizeType')
                      option(value='NONE') 선택안함
                  .form-group
                    label 템플릿 내용
                    textarea.form-control(v-model='current_template_edit.json.templateContent' rows=5)
                  .form-group
                    button.btn.btn-primary(type='submit') 템플릿 수정
            .p-4
              h5 템플릿 등록
              .form-group(v-show='profile.id')
                form(@submit.prevent='submit_template')
                  .form-group
                    label 템플릿 코드
                    input.form-control(v-model='form_create_template.templateCode' :disabled='form_create_template.use_autofill_code == "Y"')
                    b-form-checkbox(v-model='form_create_template.use_autofill_code' value='Y' unchecked-value='N' switch)
                      span(v-show='form_create_template.use_autofill_code == "Y"') 자동으로 코드 입력
                      span(v-show='form_create_template.use_autofill_code != "Y"') 수동으로 입력
                  .form-group
                    label 템플릿 이름
                    input.form-control(v-model='form_create_template.templateName')
                  .form-group
                    label 메세지 유형
                    select.form-control(v-model='form_create_template.templateMessageType')
                      option(value='BA') 기본형
                      //- option(value='EX') 부가정보형
                      //- option(value='EX') 광고추가형
                      //- option(value='MI') 복합
                  .form-group
                    label 템플릿 강조 유형
                    select.form-control(v-model='form_create_template.templateEmphasizeType')
                      option(value='NONE') 선택안함
                  .form-group
                    label 템플릿 내용
                    textarea.form-control(v-model='form_create_template.templateContent' rows=5)
                  .form-group
                    button.btn.btn-primary(type='submit') 템플릿 추가

        b-tab(title="채널 등록")
          form(@submit.prevent='submit_profile')
            .form-group
              label 카카오톡 채널아이디
              input.form-control(v-model='form_verify_sender.channel_id' required)
              span 카톡에서 채널 검색 혹은 카카오톡채널 관리자센터를 통해 채널 확인 후, @가 붙은 검색용아이디 입력
            .form-group
              label 카테고리
              .row
                .col
                  select.form-control(v-model='form_verify_sender.category1' v-if='category.firstBusinessType')
                    option(:value='e.code' v-for='e in category.firstBusinessType') {{e.name}}
                .col
                  select.form-control.async(:class='{done: (form_verify_sender.category1)}' v-model='form_verify_sender.category2' v-if='category.secondBusinessType')
                    option(:value='e.code' v-for='e in category.secondBusinessType' v-if='e.parentCode == form_verify_sender.category1') {{e.name}}
                .col
                  select.form-control.async(:class='{done: (form_verify_sender.category2)}' v-model='form_verify_sender.category3' v-if='category.thirdBusinessType')
                    option(:value='e.code' v-for='e in category.thirdBusinessType' v-if='e.parentCode == (form_verify_sender.category1+form_verify_sender.category2)') {{e.name}}
              pre {{form_verify_sender.category1}}-{{form_verify_sender.category2}}-{{form_verify_sender.category3}}
            .form-group
              label 관리자 휴대폰번호
              .row
                .col-9
                  input.form-control(v-model='form_verify_sender.phone_number' required)
                .col.pl-0
                  button.btn.btn-outline-primary.btn-block(type='button' @click='request_verify_code') 인증번호 전송
            .form-group
              label 인증번호
              input.form-control(v-model='form_verify_sender.token' required)
            .form-group
              button.btn.btn-primary(type='submit') 등록


</template>

<script>

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
    category1() {
      return this.category
    },
  },
  mounted() {
    this.load()
    setTimeout(() => {
      // this.current_template_profile = this.$store.state.kakao_config.profiles[0].id
    }, 300);
  },
  data() {
    return {
      tab_index: 0,
      loading: false,
      sms_config: {},
      phone: '',
      code_requested: false,
      pintype: '',
      code: '',

      form_verify_sender: {
        channel_id: '',
        phone_number: '',
        token: '',
        category_code: '',
        // channel_id: '@noitaler',
        // channel_id: '@mzu264w4mdewnoy',
        // channel_id: '@mintpress',
        // phone_number: '01041029921',
        // token: '',
        // category_code: '01300010001',
        // category1: '013',
        // category2: '0001',
        // category3: '0001',
      },
      category: {},
      current_template_profile: {},
      form_create_template: {
        use_autofill_code: 'Y',
        templateName: '',
        templateMessageType: 'BA',
        templateEmphasizeType: 'NONE',
        templateContent: '',
        // use_autofill_code: 'Y',
        // templateName: '테스트1',
        // templateMessageType: 'BA',
        // templateEmphasizeType: 'NONE',
        // templateContent: '[노이텔러]\n안녕하세요 #{이름}님\n카드 등록이 완료되었습니다.\n\n▶본인이 아닌경우 고객센터로 문의바랍니다.',
      },
      current_template_edit: {},
    }
  },
  methods: {
    async load() {
      this.$store.dispatch('kakao config', this.property_id)
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/kakao/profile/category`)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.category = r.data.row
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async request_verify_code() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/kakao/profile/verify_sender`, this.form_verify_sender)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.$modal.show('dialog', {
          title: '알림',
          text: '요청하신 번호로 인증번호를 보냈습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async submit_profile() {
      try {
        this.form_verify_sender.category_code = this.form_verify_sender.category1 + this.form_verify_sender.category2 + this.form_verify_sender.category3
        const r = await this.$http.post(`/v1/property/${this.property_id}/kakao/profile`, this.form_verify_sender)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        Object.keys(this.form_verify_sender).forEach(e => {
          this.form_verify_sender[e] = ''
        })

        this.$modal.show('dialog', {
          title: '알림',
          text: '등록되었습니다.',
        })
        this.$store.dispatch('kakao config', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async submit_template() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/kakao/profile/${this.current_template_profile}/template`, this.form_create_template)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.$modal.show('dialog', {
          title: '알림',
          text: '추가했습니다. 템플릿 검수 요청후 알림톡(비즈톡) 발송에 이용 가능합니다.',
        })
        this.$store.dispatch('kakao config', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async submit_template_edit() {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/kakao/profile/${this.current_template_profile}/template/${this.current_template_edit.id}`, this.current_template_edit.json)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.$modal.show('dialog', {
          title: '알림',
          text: '수정했습니다. 템플릿 검수 요청후 알림톡(비즈톡) 발송에 이용 가능합니다.',
        })
        setTimeout(() => {
          this.$store.dispatch('kakao config', this.property_id)
        }, 1000);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async request_template_review(template) {
      try {
        if(!confirm('카카오톡으로 이관되어 검수후 승인/반려까지 3-5일 소요됩니다. 검수기준과 가이드라인을 미리 숙지해주세요. 계속하시겠습니까?')) return false
        const r = await this.$http.post(`/v1/property/${this.property_id}/kakao/profile/${this.current_template_profile}/template/${template.id}/request-review`)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.$modal.show('dialog', {
          title: '알림',
          text: '심사 요청했습니다.',
        })
        setTimeout(() => {
          this.$store.dispatch('kakao config', this.property_id)
        }, 1000);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async refresh_template(template) {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/kakao/profile/${this.current_template_profile}/template/${template.id}/refresh-status`)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.$modal.show('dialog', {
          title: '알림',
          text: '새로고침 요청했습니다.',
        })
        setTimeout(() => {
          this.$store.dispatch('kakao config', this.property_id)
        }, 1000);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async delete_template(template) {
      try {
        if(!confirm('삭제한 템플릿은 복구 할수없습니다. (다시 검수요청 필요) 삭제하시겠습니다?')) return false
        const r = await this.$http.delete(`/v1/property/${this.property_id}/kakao/profile/${this.current_template_profile}/template/${template.id}`)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        setTimeout(() => {
          this.$store.dispatch('kakao config', this.property_id)
        }, 1000);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    edit_template(template) {
      this.current_template_edit = Object.assign({}, template)
    },

    async refresh_channel_status(profile) {
      try {
        // this.category_code = this.category1 + this.category2 + this.category3
        const r = await this.$http.post(`/v1/property/${this.property_id}/kakao/profile/${profile.id}/refresh-channel-status`)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        setTimeout(() => {
          this.$modal.show('dialog', {
            title: '알림',
            text: '새로고침 요청했습니다.',
          })
          this.$store.dispatch('kakao config', this.property_id)
        }, 1000);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async delete_profile(profile) {
      try {
        if(!confirm('삭제한 프로필은 복구 할수없습니다. (다시 인증 필요) 삭제하시겠습니다?')) return false
        // this.category_code = this.category1 + this.category2 + this.category3
        const r = await this.$http.delete(`/v1/property/${this.property_id}/kakao/profile/${profile.id}`)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        this.$store.dispatch('kakao config', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async test_profile(profile) {
      try {
        const phone = prompt('테스트 메시지를 받을 전화번호를 입력해주세요.')
        if(!confirm(`[${phone}] 번호로 테스트 친구톡을 보낼까요?`)) return false
        // this.category_code = this.category1 + this.category2 + this.category3
        const r = await this.$http.post(`/v1/property/${this.property_id}/kakao/profile/${profile.id}/send-test`, {
          phone,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.$modal.show('dialog', {
          title: '알림',
          text: '발송 요청했습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async test_template(template) {
      try {
        const phone = prompt('테스트 메시지를 받을 전화번호를 입력해주세요.')
        if(!confirm(`[${phone}] 번호로 테스트 알림톡(비즈톡)을 보낼까요?`)) return false
        const r = await this.$http.post(`/v1/property/${this.property_id}/kakao/profile/${template.profile_id}/template/${template.id}/send-test`, {
          phone,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        this.$modal.show('dialog', {
          title: '알림',
          text: '발송 요청했습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    view_template(profile) {
      this.current_template_profile = profile.id
      this.tab_index = 1
    }

  },
}
</script>
